import { useQuery } from '@tanstack/vue-query'

export const useReminders = () => {
    const { centralUser } = useAuth()

    const { execute } = useApi('/api/reminders', 'GET')
    const reminders = computed(() => data.value ?? [])

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['reminders', centralUser.value.id],
        queryFn: () =>
            execute({
                queryParams: {
                    all: true,
                },
            }),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return { reminders, refetch, execute, isLoading }
}
